import '../../pug/pages/index.pug'
import '../../scss/pages/index.scss'

const validate = () => {
  var isValid = true;
  ['describe-other-text', 'expect-other-text'].forEach(name => {
    const txt = document.querySelector(`input[name=${name}]`)
    if (!txt.checkValidity()) {
      const hint = document.querySelector(`.form-input-hint.${name}`)
      hint.innerText = txt.validationMessage

      // add one time listener for changes
      txt.addEventListener('input', () => hint.innerText = '', { once: true })
      isValid = false
    }
  })

  return isValid
}

const toggleText = (txt, hint) => enable => {
  txt.disabled = !enable
  hint.style.visibility = enable ? 'visible' : 'hidden'
}

document.addEventListener('DOMContentLoaded', () => {
  ['describe-other', 'expect-other'].forEach(name => {
    const checkBox = document.querySelector(`input[name=${name}]`)
    const txt = document.querySelector(`input[name=${name}-text]`)
    const parent = txt.parentNode
    const hint = parent.querySelector('.form-input-hint')
    const toggler = toggleText(txt, hint)

    checkBox.addEventListener('click', e => {
      toggler(e.target.checked)
    })

    toggler(checkBox.checked)
  })

  const button = document.querySelector('.btn.btn-primary.join-waitlist')
  document.getElementById('input-form').addEventListener('submit', (e) => {
    e.target.classList.remove('idle') // allow errors to display

    if (!validate()) {
      e.preventDefault()
      return
    }

    button.classList.add('loading')
  })
})
